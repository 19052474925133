@import '../../styles/bootstrap-custom/functions';
@import '../../styles/bootstrap-custom/variables';
@import '../../styles/bootstrap-custom/mixins';
@import '../../styles/helpers/variables';
@import '../../styles/helpers/mixins';

// Header styles
.product-page {
  position: relative;
  z-index: 2;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .title {
      @include font-size-custom(32px);
      font-family: $kanit;
      color: $gray-100;
      margin-bottom: 1rem;

      @include media-breakpoint-up(lg) {
        @include font-size-custom(64px);
      }
    }

    .subtitle {
      font-family: $barlow;
      @include font-size-custom(16px);
      color: $gray-80;
      font-weight: $regular;
      max-width: 450px;
      margin-bottom: 1rem;

      @include media-breakpoint-up(lg) {
        @include font-size-custom(22px);
      }
    }
  }

  &__image {
    display: flex;
    justify-content: flex-end;
    max-width: 510px;
    margin-left: auto;

    img {
      width: 100%;
      height: auto;

      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }
  }
}

// Product card styles
.product-item {
  margin-bottom: 4rem;
  cursor: pointer;

  &__image {
    height: 24rem;
    margin-bottom: 1.6rem;


    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .slick-slide {
      border-radius: 0.8rem;
      overflow: hidden;
      
      > div {
        height: 24rem;
      }
    }
  }

  &__price {
    font-family: $barlow;
    @include font-size-custom(14px);
    font-weight: $medium;
    color: $green-100;
    margin-bottom: 1.2rem;
  }

  &__icons {
    display: flex;
    justify-content: space-between;
  }

  &__row {
    @include make-row(-2rem);

    @include media-breakpoint-up(sm) {
      @include make-row(4rem);
    }
  }

  &__col {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-up(md) {
      @include make-col(4);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(3);
    }
  }
}

@media (max-width: 768px) {
  .side-left {
    display: none;
  }
  .image-detail {
    width: 88% !important;
  }
}

.content-modal {
  position: absolute;
  top: 0;
  width: 100%;
  padding-left: 0px;
  justify-content: space-between;
  height: 100vh;

  .side-right {
    margin-right: 0;
  }

  .side-left {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .custom-modal {
    min-width: 300px;
    background-color: #fff;
  }

  .custom-modal .modal-header {
    border-bottom: none;
  }

  .custom-modal .modal-title {
    font-size: 1.5rem;
    color: #000;
  }

  .custom-modal .flyer-image {
    width: 100%;
    height: auto;
    background-color: #e9ecef;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  .custom-modal .modal-footer {
    border-top: none;
    justify-content: center;
  }

  .btn-commander {
    background-color: #004519;
    color: #fff;
    padding: 10px 30px;
    border-radius: 20px;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .btn-commander:hover {
    background-color: #003a16;
  }
}

.content-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  z-index: 1050;
}

.side-right,
.side-left {
  height: 100vh;
}

.modal-content {
  position: relative;
  background: $white;

  .image-detail {
    position: absolute;
  }
}

.side-left {
  background-color: rgba(0, 0, 0, 0.3);
}

.custom-modal {
  max-width: 600px;
  overflow-y: auto;

  .top-green {
    height: 35vh;
    padding: 24px;
    background-color: $green-light;
  }

  .back {
    text-align: left;
    display: flex;
    color: $white;
  }

  .back-icon {
    width: 30px;
  }

  .product-title {
    text-align: center;
    color: $white;
    font-family: $kanit;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .back-style {
    font-family: $barlow;
    padding-left: 10px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .bottom-white {
    height: 65vh;
    background-color: $white;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 17rem;
  }

  .description,
  .price {
    display: flex;
    color: var(--grey-100, #1d1d1b);
    font-family: $barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  .price {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .description-value {
    padding-left: 2.5rem;
  }

  .price-value {
    padding-left: 8rem;
    padding-bottom: 32px;
  }

  .order {
    font-family: $barlow;
    color: $white;
    font-weight: 600;
    width: 100%;
    border-radius: 10px;
    background-color: $primary-color;
    height: 35px;

    &:hover {
      background-color: $green-light;
      border: 0;
    }
  }
}
