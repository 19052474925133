.burger-menu {
    .burger-hidden & {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }

    &,
    &:before,
    &:after {
        background-color: $white;
        position: absolute;
        left: 0;
        height: 0.3rem;
        width: 2.4rem;
        transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
        border-radius: 1px;
    }

    & {
        .menu-open & {
            background: rgba($black, 0);
        }
    }

    &:before {
        content: "";
        margin-top: -6px;

        .menu-open & {
            margin-top: 0px;
            transform: rotate(405deg);
        }
    }

    &:after {
        content: "";
        margin-top: 6px;

        .menu-open & {
            margin-top: 0px;
            transform: rotate(-405deg);
        }
    }
}
