* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
}

body {
  font-family: $barlow;
  overflow-x: hidden;
  font-weight: $regular;
  color: $gray;
  @include font-size-custom(16px);
  line-height: 1.375;
  background: $linen;
}

.modal-open {
  padding-right: 0 !important;
}

.w-100 {
  width: 100%;
}

ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;

  li {
    display: block;
  }
}

.main-container {
  padding-top: 8rem;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding-top: 11.2rem;
  }

  &--w {
    &-bg {
      &:before {
        content: "";
        width: 100%;
        height: 38.2rem;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        background: $green-light;
        z-index: 1;
      }

      &-no {
        &:before {
          display: none;
        }
      }

      &-border {
        &:before {
          border-bottom: 0.2rem solid $green-100;
        }
      }

      &-search {
        &:before {
          height: 19rem;
          border: none;
        }
      }
    }
  }
}

//colors
.color {
  &-white {
    color: $white;

    svg {
      stroke: $white;
    }
  }
}

.paragraph {
  @include font-size-custom(22px);
  line-height: 3.2rem;
}

.container {
  &--small {
    max-width: 107.2rem;
  }
}

.sidebar {
  margin-top: 0.8rem;
}
