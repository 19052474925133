.block {

  &--discussion {
    position: relative;
    z-index: 2;
  }

  &--files {
    padding-right: 2.3rem;
  }

  &--mb {
    &-45 {
      margin-bottom: 4.5rem;
    }
  }
}