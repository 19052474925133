@import '../../styles/bootstrap-custom/functions';
@import '../../styles/bootstrap-custom/variables';
@import '../../styles/bootstrap-custom/mixins';
@import '../../styles/helpers/variables';
@import '../../styles/helpers/mixins';

.discussion {
  &__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 2.4rem;
    margin-top: -4.4rem;
  }

  &__btn {
    margin: 0 1rem;
  }

  &__list {
    width: 100%;
  }

  &__msg {
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;
  }

  &__subtitle {
    font-family: $kanit;
    @include font-size-custom(16px);
    color: $gray-100;
    font-weight: $regular;

    @include media-breakpoint-up(lg) {
      @include font-size-custom(18px);
    }

    .discussion__results & {
      margin-bottom: 1.2rem;
    }
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.6rem;
  }

  &__infos {
    max-width: 89rem;
    width: 100%;
    position: relative;
  }

  &__create {
    @include media-breakpoint-up(xxl) {
      position: absolute;
      top: 0;
      left: calc(100% + 8.4rem);
      min-width: 24.1rem;
    }
  }

  &__back {
    font-family: $barlow;
    @include font-size-custom(18px);
    color: $white;
    font-weight: $semibold;
    margin-bottom: 1.9rem;
    cursor: pointer;

    span {
      margin-right: 0.8rem;
    }

    .banner--with-search & {
      margin-bottom: 4rem;
    }
  }

  &__results {
    max-width: 90rem;
    width: 100%;
    margin: 6rem auto;
  }

  &__bullet {
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem;
    height: 1rem;
    background: $error;
    border-radius: 50%;
  }

  &-wysiwyg {
    padding: 1.8rem;
    border-top: 1px solid $green-60;
    position: relative;

    &__btns {
      margin-top: 0.8rem;
    }
  }

  &-item {
    padding: 1.6rem 0;
    border-bottom: 1px solid $green-60;

    &:last-child {
      border-bottom: none;
    }

    &__btn {
      display: block;
      cursor: pointer;

      .btn--lock {
        margin-left: 8px;
      }
    }

    &__header {
      margin: 0 0 2.4rem;
    }

    &__content {
      p {
        margin: 0;
      }
    }

    &__media {
      margin: 1.6rem 0 0;

      > * {
        padding: 0.8rem;
      }
    }

    &__image {
      border-radius: 0.4rem;
      margin-bottom: 2.5rem;
      height: 15rem;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        height: 20rem;
      }

      img {
        width: 100%;
        height: 15rem;
        object-fit: cover;

        @include media-breakpoint-up(md) {
          height: 20rem;
        }
      }
    }

    .quill-buttons {
      margin-top: 1rem;
      margin-right: 1rem;
      display: flex;
      justify-content: end;

      .replying {
        margin-left: 2rem;
      }
    }
  }
}

.no-reply {
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
  padding-top: 2rem;

  .btn__no-reply {
    height: 3.5rem;
    border: 1px solid var(--gray-400, #98a2b3);
    background: var(--gray-50, #f9fafb);
    font-family: $barlow;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 18px;
  }
}

.new-messages {
  position: relative;
  font-family: $kanit;
  @include font-size-custom(12px);
  font-weight: $medium;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  color: $green-80;

  &__title {
    padding: 0 1rem;
    background: $linen;
    position: relative;
  }

  &:before {
    content: '';
    width: 100%;
    height: 0.2rem;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: $green-80;
  }
}

.list-message {
  background: $white;
  border: 1px solid $gray-200;
  border-radius: 1rem;
  margin-bottom: 3.2rem;
  box-shadow: 5px -5px 0px 0px #6a9177;
  cursor: pointer;

  &--no {
    &-border {
      box-shadow: none;
    }
  }

  &__item {
    padding: 1.2rem 1.6rem;
    border-bottom: 0.1rem solid $gray-200;

    &:last-child {
      border: none;
    }
  }

  &__top {
    justify-content: space-between;
  }

  &__title {
    font-family: $kanit;
    @include font-size-custom(18px);
    line-height: 2.4rem;
    color: $gray-100;
    font-weight: $medium;
    margin-bottom: 1.4rem;

    @include media-breakpoint-up(lg) {
      @include font-size-custom(20px);
      margin-bottom: 1.6rem;
    }
  }
}

.message-info {
  flex-wrap: wrap;

  &__icon {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.3em;
    margin-right: 0.8rem;
    overflow: hidden;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  &__name {
    font-family: $barlow;
    @include font-size-custom(16px);
    line-height: 1.6;
    color: $gray-80;
    font-weight: $medium;

    .banner & {
      color: $white;
    }
  }
}

.pin-content {
  display: flex;
}

.list-notif {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  width: 100%;

  @include media-breakpoint-up(sm) {
    margin-top: 0;
    width: auto;
  }

  &__item {
    font-family: $barlow;
    @include font-size-custom(14px);
    line-height: 1;
    color: $gray-60;
    font-weight: $medium;
    display: flex;
    align-items: center;
    position: relative;

    .banner & {
      color: $white;
      opacity: 0.8;
    }

    &:before {
      content: '';
      display: inline-flex;
      width: 0.4rem;
      height: 0.4rem;
      margin: 0 0.8rem;
      background: $gray-60;
      border-radius: 50%;
      .banner & {
        background: $white;
        opacity: 0.8;
      }
    }

    &:first-child {
      &:before {
        display: none;

        @include media-breakpoint-up(sm) {
          display: inline-flex;
        }
      }
    }
  }
}

.banner {
  padding: 9.7rem 0 4rem;
  position: relative;
  background: $green-light;
  color: $white;

  &__back,
  &__desc {
    margin: 0 0 3.2rem;
  }

  &__bigtitle {
    @include font-size-custom(35px);
    line-height: 1;
    color: $white;
    text-align: center;
    padding-top: 3.1rem;
    margin-bottom: 3.2rem;

    @include media-breakpoint-up(xl) {
      @include font-size-custom(70px);
    }
  }

  &__subtitle {
    font-family: $barlow;
    @include font-size-custom(18px);
    line-height: 1;
    font-weight: $regular;
    color: $white;
    text-align: center;
    margin-bottom: 2.4rem;

    @include media-breakpoint-up(xl) {
      @include font-size-custom(22px);
      margin-bottom: 3.2rem;
    }
  }

  &--w-artwork {
    @include media-breakpoint-up(lg) {
      min-height: 47.5rem;
      background: url('../../../public/assets/static/art/rafiki.svg') no-repeat
        100% 100% $green-light;
    }
  }

  &__inner {
    @include media-breakpoint-up(lg) {
      margin-right: 10rem;
    }
  }

  &__search {
    position: relative;
    max-width: 36rem;
    width: 100%;
    margin: 0 auto;

    .banner--with-search & {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -3rem;
      margin: 0 auto;
      max-width: 53rem;
    }
  }

  &__iconsearch {
    position: absolute;
    top: 50%;
    left: 1.6rem;
    height: 2rem;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;

    .banner--with-search & {
      width: 3rem;
      height: 3rem;

      svg {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  &__iconclose {
    position: absolute;
    top: 50%;
    right: 2.4rem;
    height: 2.4rem;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    display: none;

    .banner--with-search & {
      display: block;
    }
  }

  &--with {
    &-border {
      padding-bottom: 8rem;
      border-bottom: 0.2rem solid $green-100;
    }
  }
}

.profile-status {

  &__img {
    width: 3rem;
    height: 3rem;
    border-radius: 0.4rem;
    overflow: hidden;
    margin-right: 0.8rem;

    img {
      width: 3rem;
      height: 3rem;
      object-fit: cover;
    }
  }

  &__content {
    padding-left: 0.8rem;
  }

  &__name {
    margin: 0 0 0.8rem;
    font-family: $kanit;
    @include font-size-custom(18px);
    line-height: normal;
    font-weight: $medium;
    text-decoration: none;
    color: $gray;
  }

  &__date {
    margin: 0;
    @include font-size-custom(14px);
    line-height: normal;
    color: $gray-60;
  }
}

.message {
  margin-bottom: 0.8rem;
  padding: 0.8rem;
  border-radius: 0.8rem;
  border: 0.1rem solid $gray-400;
  @include font-size-custom(14px);
  font-style: italic;
  line-height: 1.8rem;
  background: $gray-50;
}

.config {
  margin-bottom: 2.4rem;
  padding: 2.4rem 1.6rem;
  border-radius: 0.8rem;
  border: 0.15rem solid $green-100;
  background: $gray-50;

  &__heading {
    margin: 0 0 3.2rem;
    font-weight: $medium;
    text-align: center;
    color: $green-100;
  }

  &__subtitle {
    padding: 0 0 0.4rem;
    margin: 0 0 1.6rem;
    border-bottom: 0.15rem solid $green-100;
    @include font-size-custom(18px);
    font-weight: $medium;
    line-height: 1;
    color: $green-100;
  }

  &__list {
    &:not(:last-child) {
      margin: 0 0 3.2rem;
    }
    li {
      &:not(:last-child) {
        margin-bottom: 1.6rem;
      }
    }
  }
}

.add-note {
  position: absolute;
  top: 5.8rem;
  right: 3.2rem;
  cursor: pointer;

  label {
    cursor: pointer;
  }

  svg {
    stroke: $gray-1;
  }

  .quill-container & {
    top: 1.2rem;
    right: 1.5rem;
  }
}

.quill {
  margin-bottom: 1rem;

  &-container {
    position: relative;
  }
}

.delete-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  height: 2.4rem;
  margin-left: 2rem;
  transform: translateY(-50%);
  cursor: pointer;
}

.file-wrapper {
  margin-bottom: 2rem;
}
