@import "../../styles/bootstrap-custom/functions";
@import "../../styles/bootstrap-custom/variables";
@import "../../styles/bootstrap-custom/mixins";
@import "../../styles/helpers/variables";
@import "../../styles/helpers/mixins";

.profil {
  &__image {
    width: 8rem;
    height: 8rem;
    border-radius: 1rem;
    overflow: hidden;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 1.6rem;
    transform: translateY(-50%);
    cursor: pointer;

    .register-form & {
      right: 0;
    }
  }
}

.profil-content {
  display: flex;
  justify-content: center;
}

.referral {
  &__person {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.6rem;
    border-radius: 0.8rem;
    border: 1px solid $gray-300;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
  }

  &__image {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.8rem;
    border-radius: 0.5rem;
    overflow: hidden;
    flex-shrink: 0;

    @include media-breakpoint-up(lg) {
      width: 4rem;
      height: 4rem;
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 0.3rem;
    flex-shrink: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background: url("../../../public/assets/static/icons/arrow-up-right.svg") 50% 50% no-repeat transparent;
    background-size: 100%;
    cursor: pointer;
    transform: translateY(-50%);

    @include media-breakpoint-up(lg) {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  p {
    font-family: $barlow;
    font-weight: $regular;
    @include font-size-custom(14px);
    line-height: 1;
    color: $gray-1;
    margin-bottom: 1.4rem;
  }

  .name {
    font-family: $barlow;
    font-weight: $regular;
    @include font-size-custom(12px);
    font-weight: 500;
    color: $gray-100;
    margin: 0;
    padding-right: 2rem;
    flex-grow: 1;

    @include media-breakpoint-up(lg) {
      @include font-size-custom(16px);
      padding-right: 2.8rem;
    }
  }
}

.list-referral {
  @include make-row(10px);
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  margin-bottom: 2.2rem;
  position: relative;

  @include media-breakpoint-up(lg) {
    @include make-row(32px);
  }

  &__item {
    @include make-col-ready();
    @include make-col(6);
    margin-bottom: 2.2rem;

    &:last-child {
      .no-child & {
        display: none;
      }
    }
  }
}

.with-child {
  &:after {
    content: "";
    position: absolute;
    top: calc(100% - 18px);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 7.8rem;
    height: 2.9rem;
    background: url("../../../public/assets/static/icons/qui-a-parraine.png") 50% 50% no-repeat transparent;

    @include media-breakpoint-up(lg) {
      top: calc(100% - 22px);
    }
  }

  &--pt {
    &:after {
      content: "";
      position: absolute;
      top: calc(100% - 18px);
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 8.4rem;
      height: 3.1rem;
      background: url("../../../public/assets/static/icons/par-pt.png") 50% 50% no-repeat transparent;

      @include media-breakpoint-up(lg) {
        top: calc(100% - 22px);
      }
    }
  }
}

.with-parental {
  &:after {
    content: "";
    position: absolute;
    top: calc(100% - 18px);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 12.7rem;
    height: 2.9rem;
    background: url("../../../public/assets/static/icons/qui-a-ete-parraine-par.png") 50% 50% no-repeat transparent;

    @include media-breakpoint-up(lg) {
      top: calc(100% - 22px);
    }
  }

  &--pt {
    &:after {
      content: "";
      position: absolute;
      top: calc(100% - 18px);
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 11.1rem;
      height: 2.7rem;
      background: url("../../../public/assets/static/icons/qui-pt.png") 50% 50% no-repeat transparent;

      @include media-breakpoint-up(lg) {
        top: calc(100% - 22px);
      }
    }
  }
}

.view-mode {
  &__profil {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3.2rem;
  }

  &__image {
    width: 8rem;
    height: 8rem;
    margin-right: 2.4rem;
    border-radius: 1rem;
    overflow: hidden;
  }
}

.moderator-title {
  font-family: $barlow;
  @include font-size-custom(12px);
  color: $white;
  font-weight: $semibold;
  text-transform: uppercase;
  padding: 0.8rem;
  background: $primary-color;
  border-radius: 0.8rem;
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    @include font-size-custom(14px);
    padding: 1.2rem;
  }
}


.deconnect {
  text-align: center;
  margin-bottom: 2rem;

  span {
    font-family: $barlow;
    @include font-size-custom(16px);
    color: $gray;
    font-weight: $bold;
    text-decoration: underline;
    cursor: pointer;
  }
}