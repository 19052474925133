@function rem($px, $base: 10px) {
    @return $px / $base * 1rem;
}

@mixin font-size-custom($size) {
    font-size: $size;
    font-size: rem($size);
}

@mixin line-height-custom($size) {
    line-height: $size;
    line-height: rem($size);
}


@mixin transition-custom($property: all, $time: 300ms, $a: 0.215, $b: 0.61, $c: 0.355, $d: 1, $delay: 0ms) {
    transition: $property $time cubic-bezier($a, $b, $c, $d) $delay;
}


@mixin image-fit-area($layout) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @if $layout=="wfha" {
        width: 100%;
        height: auto;

    }

    @else if $layout=="wahf" {
        width: auto;
        height: 100%;
    }
}


@mixin one-box-shadow($h-shadow: 0, $v-shadow: 5px, $blur: 19px, $spread: -2px, $r: 0, $g: 0, $b: 0, $a: 0.85) {
    -webkit-box-shadow: $h-shadow $v-shadow $blur $spread rgba($r, $g, $b, $a);
    -moz-box-shadow: $h-shadow $v-shadow $blur $spread rgba($r, $g, $b, $a);
    box-shadow: $h-shadow $v-shadow $blur $spread rgba($r, $g, $b, $a);
}


@function multiply ($number, $times) {
    @return $number*$times;
}


@mixin render-frame($color, $pad, $bg:none) {
    $padX2: multiply($pad, 2);
    content: '';
    position: absolute;
    top: 0;
    left: $pad;
    width: calc(100% - #{$padX2});
    height: 100%;
    border: 0.1rem solid $color;
    background: $bg;
    z-index: -1;
}


@mixin flex-parent-with-alignment($vertical:center, $horizontal:flex-start) {
    display: flex;
    align-items: $vertical;
    justify-content: $horizontal;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(100px);
    }
}