@import '../../../styles/bootstrap-custom/functions';
@import '../../../styles/bootstrap-custom/variables';
@import '../../../styles/bootstrap-custom/mixins';
@import '../../../styles/helpers/variables';
@import '../../../styles/helpers/mixins';

.info-modal {
  padding-right: 0 !important;

  .modal-dialog {
    max-width: 48.7rem;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    margin: 0;
    animation: fadeInRight 1s;

    @include media-breakpoint-up(sm) {
      margin-left: auto;
    }
  }

  .modal-dialog.closed {
    animation: fadeOutRight 1s;
  }

  &--no {
    &-green {
      .modal-dialog {
        max-width: 58rem;
      }
    }
  }
}

.modal {
  &-right {
    margin-right: 0;
  }

  &-header {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 0;
    z-index: 2;
  }

  &-body {
    flex: initial;
    position: relative;
    padding: 0 0 1rem;
    z-index: 2;
  }

  &-content {
    height: 100vh;
    padding: 2.4rem;
    border: none;
    overflow-y: auto;
    position: relative;
    background: $white;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 29rem;
      background: $green-light;
      z-index: 1;

      .info-modal--no-green & {
        display: none;
      }
    }
  }

  &-footer {
    .info-modal--no-green & {
      margin: 0;
    }
  }

  &-title {
    font-family: $kanit;
    @include font-size-custom(30px);
    font-weight: $regular;
    line-height: 3.2rem;
    color: $white;
    margin: 0 auto;
    padding: 2.4rem 0 3.2rem;
    text-align: center;
  }

  &-img {
    margin: 0 0 3.2rem;
    img {
      width: 100%;
      height: 26rem;
      border-radius: 1rem;
      display: block;
      object-fit: cover;
    }
  }

  &-desc {
    @include font-size-custom(14px);
    font-family: $kanit;
    line-height: 3.2rem;

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0 0 2rem;
        display: flex;
      }

      // p {
      //   font-weight: $regular;
      //   line-height: 1;
      // }
    }

    &__title {
      font-family: $kanit;
      @include font-size-custom(14px);
      line-height: 1;
      color: $gray-80;
      font-weight: $semibold;
      width: 100%;
      max-width: 8rem;
      flex: 0 0 8rem;
      margin-right: 1.6rem;
      padding-top: 0.6rem;
      text-align: left;
    }

    &__text {
      font-family: $barlow;
      @include font-size-custom(16px);
      color: $gray-100;
      font-weight: $regular;
      line-height: 2.2rem;
      text-align: left;
    }
  }

  &-footer {
    position: relative;
    padding: 0;
  }
}

.btn-back {
  display: flex;
  align-items: center;
  color: $white;
  cursor: pointer;
  @include transition-custom();

  &:hover,
  &:focus {
    opacity: 0.75;
  }

  &__text {
    font-family: $barlow;
    @include font-size-custom(18px);
    font-weight: $semibold;
    color: $white;
    line-height: 1;
    margin-left: 0.8rem;

    &--green {
      color: $gray-100;
      font-weight: $bold;
    }
  }
}
.slick-prev:before,
.slick-next:before {
  color: #cecccc;
}
