@import '../../styles/bootstrap-custom/functions';
@import '../../styles/bootstrap-custom/variables';
@import '../../styles/bootstrap-custom/mixins';
@import '../../styles/helpers/variables';
@import "../../styles/helpers/mixins";

.menu {
  padding: 1.2rem 1.6rem;
  margin: 0 1rem;
}

.profile {
  padding-left: 0;
  object-fit: cover;
}

.info {
  position: relative;
}

.username {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background: $white;

    .isConnected & {
      display: none;
    }
  }

  .name {
    font-family: $barlow;
    @include font-size-custom(16px);
    color: $white;
    font-weight: $medium;
    line-height: 1;
    text-decoration: none;
    padding-left: 1rem;
  
    .isConnected & {
      color: $gray-80;
    }
  }
}

.nav-right {
  position: relative;

  .dropdown-menu {
    padding: 3rem;
    border-radius: 2rem;
    background-color: #a1f1a1;
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    transform: translateY(8px);
    z-index: 1000;
  }
}
