@import '../../styles/bootstrap-custom/functions';
@import '../../styles/bootstrap-custom/variables';
@import '../../styles/bootstrap-custom/mixins';
@import '../../styles/helpers/variables';
@import "../../styles/helpers/mixins";

.green {
  background-color: $green-light;
  width: 100%;
  height: 40vh;
  position: relative;
}

.white {
  position: relative;
  background-color: rgb(233, 241, 235);
  width: 100%;
}

.form-wrapper {
  width: 100%;
  max-width: 94rem;
  margin: 0 auto;
  position: relative;
  background: $white;
  padding: 2rem 1.6rem;
  border-radius: 1.6rem;
  z-index: 2;

  @include media-breakpoint-up(lg) {
    padding: 3.2rem;
  }
}

.content {
  position: absolute;
  top: 10vh;
  width: 100%;
}

.register-container {
  font-family: "Kanit", sans-serif;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;

  .register-form {
  }

  h2 {
    color: #004519;
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
  }

  .file-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
    margin-top: 8px;

    .file-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 8px;
    }

    .delete-file-button {
      margin: 0;
      padding-top: 0px;
      color: 495057;
      border-radius: 50%;
      border: 1px solid #495057;
      font-weight: 700;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .file-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid #ced4da;
      border-radius: 4px;
      margin-top: 8px;
    }
  }

  .dropzone {
    height: 10.4rem;
    border-radius: 5px;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;

    &:focus {
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  .dropzone {
    position: relative;
    text-align: center;
    background-color: #f8f9fa;
    cursor: pointer;
    transition: border-color 0.24s ease-in-out;

    label {
      display: block;
      margin-top: 8px;
      cursor: pointer;
      //   background: url('/assets/static/icons/cloud_upload.png') no-repeat center center;
      //   background-size: 24px 24px;
      padding-top: 2rem;
    }

    &:hover,
    &.drag-active {
      border-color: #80bdff;
    }

    &.drag-active {
      background-color: #e2e6ea;
    }
  }

  .btn-primary {
    background-color: #004519;
    border-color: #28a745;
    color: #fff;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 1rem;
    line-height: 1.5;
    width: 100%;

    &:hover {
      background-color: #218838;
      border-color: #1e7e34;
    }
  }
}

.login-container {
  font-family: "Kanit", sans-serif;
  justify-content: center;
  align-items: center;
}

.form-w-padding {
  width: 100%;
  max-width: 44.5rem;
  margin: 0 auto;
}
