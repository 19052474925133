@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300&family=Kanit:wght@300&display=swap');

.ql-editor {
  min-height: 100px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_delete {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 33%;
  margin: 2rem;
  min-width: 300px;
  z-index: 1051;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.modal-content_ {
  display: flex;
  flex-direction: column;
}

.modal-header .modal-icon {
  font-size: 24px;
  color: #ffc107;
  text-align: center;
}

.modal-body {
  margin-top: 10px;
  text-align: center;

  h4 {
    color: #004519;
    font-family: 'Kanit', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 2rem;
  }

  // p {
  //   color: #23231f;
  //   font-family: 'Kanit', sans-serif;
  //   font-size: 18px;
  //   font-style: normal;
  //   font-weight: 300;
  //   line-height: normal;
  //   padding-top: 1rem;
  // }
}

.modal-footer__delete {
  margin-top: 20px;
  text-align: center;
  display: grid;
  justify-content: center;
}

.btn-cancel,
.btn-confirm {
  margin: 0 10px;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 3.8rem;
}

.btn-confirm {
  padding: 0 30px; 
}

.btn-cancel {
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  // font-family: 'Barlow Condensed', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}