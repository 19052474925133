@import "../../styles/bootstrap-custom/functions";
@import "../../styles/bootstrap-custom/variables";
@import "../../styles/bootstrap-custom/mixins";
@import "../../styles/helpers/variables";
@import "../../styles/helpers/mixins";
@import "burger";

.header {
    width: 100%;
    padding: 1.2rem;
    position: fixed;
    top: 0;
    left: 0;
    background: $green-light;
    z-index: 9;

    @include media-breakpoint-up(md) {
        padding: 1.2rem 3.2rem;
    }

    &-toggler {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &-brand {
        width: 17.8rem;
    }
}

.isConnected {
    border-bottom: 0.1rem solid $alto;
    background: $linen;
}

.navbar {
    &-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-toggler {
        width: 2.4rem;
        height: 2.4rem;
        border: none;
        background: none;
        position: absolute;
        top: 1.3rem;
        right: 1.5rem;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &-brand {
        width: 17.8rem;
        display: block;
    }

    &-collapse {
        width: 100%;
        padding: 2rem 0;
        position: absolute;
        top: 100%;
        left: 0;
        background: $white;
        z-index: 9;
        display: none;

        .menu-open & {
            display: block;
        }

        @include media-breakpoint-up(md) {
            width: auto;
            position: static;
            background: none;
            display: block;
        }
    }
}

.nav {
    padding: 0;
    margin: 0;
    list-style: none;

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
    }

    &-item {
        margin: 0 1.6rem;
    }

    &-link {
        @include font-size-custom(16px);
        font-weight: $medium;
        text-decoration: none;
        color: $white;
        position: relative;
        transition:
            background-color 0.3s,
            color 0.3s;

        &:hover {
            color: $primary-color;
        }

        &.active {
            .isConnected & {
                color: $gray-100;
                background: none;
            }
        }

        .isConnected & {
            color: $gray-80;
        }

        .header--connected & {
            color: $white;
        }

        &:hover,
        &:focus,
        &.active {
            background: none;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -2.4rem;
                height: 0.2rem;
                background: $primary-color;

                .isNotConnected &,
                .header--connected & {
                    background: $white;
                }
            }

            .isNotConnected & {
                color: $white;
            }
        }
    }

    &-bullet {
        position: absolute;
        top: -0.5rem;
        right: -1.3rem;
        width: 1rem;
        height: 1rem;
        background: $white;
        border-radius: 50%;

        .isConnected & {
            background: $gray-80;
        }
    }
}

.list-connexion {
    &__item {
        margin: 0 0.2rem;
    }
}
