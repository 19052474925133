.btn {
  font-family: $barlow;
  @include font-size-custom(16px);
  font-weight: $medium;
  line-height: 1;
  padding: 1.3rem 1.6rem;
  border: 0.1rem solid;
  border-radius: 0.8rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background: none;
  @include transition-custom;

  &--primary {
    border-color: $primary-color;
    background: $primary-color;
    color: $white;
    stroke: $white;

    &:hover,
    &:focus {
      background: none;
      color: $primary-color;
    }
  }

  &--secondary {
    @include font-size-custom(14px);
    color: $gray-1;
    border-color: $slate-200;
    background: $white;
    stroke: $gray-1;

    &:hover,
    &:focus {
      background: $slate-200;
    }
  }

  &--full {
    width: 100%;
  }

  &--wrapper {
    .btn {
      margin: 0 0.8rem;
    }
  }

  &--link {
    &:hover {
      path {
        stroke: $primary-color;
      }
    }
  }

  &--normal {
    border: none;
    text-decoration: none;
    color: $white;

    // @include media-breakpoint-up(md) {
    //   color: $white;
    // }

    &:hover,
    &:focus {
      background: $white;
      color: $primary-color;
    }

    &-no {
      &-bg {
        &:hover,
        &:focus {
          color: $white;
          background: none;
        }
      }
    }

    &-regular {
      font-weight: $regular;
      border-bottom: 0.2rem solid transparent;
      border-radius: 0;
      position: relative;
    }

    &-bold {
      font-weight: $medium;
      border-bottom: 0.2rem solid $white;
    }
  }

  &--lock {
    @include font-size-custom(12px);
    padding: 0.8rem 1rem;

    &:hover,
    &:focus {
      background: $primary-color;
      color: $white;
    }
  }

  &--active {
    background: $white;
    color: $primary-color;
  }

  &-group {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 0.8rem;
    }
  }

  &-w-icon {
    display: flex;
    align-items: center;
    font-family: $barlow;
    @include font-size-custom(16px);
    font-weight: $medium;
    line-height: 1.6rem;
    text-decoration: none;
    color: $gray-80;

    svg {
      margin-right: 0.8rem;
      stroke: $green-100;
    }
  }
}
