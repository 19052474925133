input[type='text'],
input[type='tel'],
input[type='email'],
input[type='date'],
input[type='time'],
input[type='number'],
input[type='password'],
select,
textarea {
  width: 100%;
  border: none;
  border-radius: 0.8rem;
  border: 0.1rem solid $gray-300;
  padding: 1.15rem 1.4rem;
  font-family: $barlow;
  font-weight: $regular;
  @include font-size-custom(16px);
  line-height: 1;
  color: $gray;
  background: $white;
  outline: none;

  .error & {
    border-color: $red;
    margin-bottom: 1.6rem;
  }

  .banner__search & {
    padding-left: 4.2rem;
  }

  .banner--with-search & {
    @include font-size-custom(23px);
    color: $gray-100;
    font-weight: $bold;
    padding: 1.7rem 5rem 1.7rem 5.8rem;
  }
}

//Textarea
textarea {
  resize: none;
}

//Select
select {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('../../../public/assets/static/icons/select-down.svg') 98% 50%
    no-repeat transparent;
}

.is-invalid {
  margin-bottom: 1.6rem;
}

.form-field,
.form-group {
  margin-bottom: 3.2rem;
}

label {
  margin: 0 0 0.8rem;
  display: block;
  font-family: $kanit;
  @include font-size-custom(20px);
  font-weight: $medium;
  line-height: 1;
}

.password-input {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 1.3rem;
  right: 1.4rem;
  background: url('../../../public/assets/static/icons/icon-eye.svg') 50% 50%
    no-repeat transparent;
  cursor: pointer;
}

.password-visible {
  background: url('../../../public/assets/static/icons/icon-eye-visible.svg')
    50% 50% no-repeat transparent;
}

// .form-wrapper {
//   position: relative;
//   background: $white;
//   padding: 3.2rem;
//   border-radius: 1.6rem;
//   z-index: 2;
// }

// .error-message {
//   @include font-size-custom(14px);
//   font-style: italic;
//   line-height: 2rem;
//   color: $red;
// }

.form-item {
  display: flex;

  flex-direction: column;
  padding: 2.4rem 0;
  border-bottom: 1px solid rgba(201, 200, 200, 0.4);

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }

  &--no {
    &-border {
      padding: 3.2rem 0;
      border: none;
    }
  }

  &__left {
    max-width: 30rem;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin-right: 3.2rem;
    }

    label {
      @include media-breakpoint-up(lg) {
        margin: 0;
      }
    }

    span {
      font-family: $barlow;
      @include font-size-custom(16px);
      font-weight: $regular;
      color: $gray-60;
      line-height: 1;
      display: inline-block;
      margin-bottom: 1.4rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }

  &__right {
    max-width: 38rem;
    width: 100%;
    position: relative;

    &--referral {
      @include media-breakpoint-up(lg) {
        max-width: calc(100% - 33.2rem);
      }
    }

    .register-form & {
      max-width: 100%;
    }
  }

  &__desc {
    font-family: $barlow;
    font-weight: $regular;
    @include font-size-custom(16px);
    line-height: 1;
    color: $gray;
    width: 100%;
  }

  &__link {
    padding: 1.6rem 4rem 1.6rem 1.4rem;
    border: 1px solid rgba(201, 200, 200, 0.4);
    border-radius: 1.6rem;

    p {
      font-family: $barlow;
      font-weight: $regular;
      @include font-size-custom(14px);
      line-height: 1;
      color: $gray-1;
    }
  }
}

.copy-icon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 50%;
  right: 1.2rem;
  transform: translateY(-50%);
  background: url('../../../public/assets/static/icons/copy.svg') 50% 50%
    no-repeat transparent;
  cursor: pointer;
}

.copy-icon-clicked {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 50%;
  right: 1.2rem;
  transform: translateY(-50%);
  background: url('../../../public/assets/static/icons/checked.svg') 50% 50%
    no-repeat transparent;
  cursor: pointer;
}

//Message error form
.error-message {
  font-family: $barlow;
  @include font-size-custom(14px);
  line-height: 2rem;
  font-weight: $regular;
  color: $red;
}

.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.6rem;
  border: 0.1rem solid $gray-200;
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;

  .discussion-item__media & {
    margin-bottom: 2.5rem;
  }

  .error & {
    border-color: $red;
  }

  &__icon {
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
    display: block;
    border-radius: 50%;
    display: flex;
    align-items: center;
    background: url('../../../public/assets/static/icons/icon-upload.svg') 50%
      50% no-repeat rgba($green-lighter, 0.3);
  }

  p {
    margin: 0.8rem 0 0;
  }

  &--pdf {
    flex-direction: row;
    justify-content: flex-start;
    padding: 1.2rem;

    .block--files & {
      padding: 1.2rem 4rem 1.2rem 1.2rem;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-bottom: 0.8rem;
    background: rgba(195, 208, 199, 0.3)
      url('../../../public/assets/static/icons/upload.svg') 50% 50% no-repeat;

    &--pdf {
      width: 3rem;
      height: 3rem;
      margin-right: 0.8rem;
      background: url('../../../public/assets/static/icons/pdf.svg') no-repeat 0 0;
      flex-shrink: 0;
    }
  }

  &__text {
    font-family: $barlow;
    @include font-size-custom(14px);
    line-height: 1;
    color: $gray-60;
    font-weight: $regular;

    &--pdf {
      display: flex;
      flex-direction: column;
      font-family: $barlow;
      @include font-size-custom(16px);
      line-height: 1;
      color: $gray-100;
      font-weight: 500;
    }

    .click {
      font-weight: 600;
      text-decoration: underline;
      padding-right: 0.3rem;
    }

    .name {
      margin-bottom: 0.8rem;
    }

    .size {
      @include font-size-custom(14px);
      font-weight: $regular;
      color: $gray-80;
    }
  }

  &__close {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    right: 1.2rem;
    transform: translateY(-50%);
    background: url('../../../public/assets/static/icons/close.svg') no-repeat 0
      0 transparent;
    cursor: pointer;
  }

  // .file-wrapper & {
  //   margin-right: 1rem;
  // }
}

//Dropzone
.dropzone {
  &.error {
    margin-bottom: 1.6rem;
  }
}

.drag-active {
  display: none;
}

.switch {
  position: relative;

  label {
    position: relative;
    font-family: $barlow;
    @include font-size-custom(16px);
    font-weight: $medium;
    line-height: 2.4rem;
    display: flex;
    align-items: center;
    color: $gray-80;

    &:before {
      content: '';
      display: inline-block;
      width: 3.6rem;
      height: 2rem;
      margin-right: 0.8rem;
      border-radius: 2rem;
      background-color: $conch;
      transition: all 0.3s;
    }

    &:after {
      content: '';
      position: absolute;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background-color: $white;
      top: 0.4rem;
      left: 0.2rem;
      transition: all 0.3s;
    }
  }

  [type='checkbox'] {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    &:checked {
      + label {
        &:before {
          background-color: $green-80;
        }

        &:after {
          left: 1.8rem;
        }
      }
    }
  }
}
