.slick {
  &-slider {
    .slick-track {
      padding-bottom: 1.6rem;
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: center;
      bottom: -1.5rem;

      li {
        width: 1rem;
        height: 1rem;

        button {
          width: 1rem;
          height: 1rem;

          &:before {
            width: 1rem;
            height: 1rem;
            line-height: 1rem;
            opacity: 0.5;
          }
        }
      }
    }
  }

  &-prev {
    z-index: 1;
    .product-item &,
    .modal & {
      width: 1.5rem;
      height: 1.5rem;
      left: 1rem;

      &:before {
        font-size: 1.8rem;
        color: $white;
      }
    }
  }

  &-next {
    z-index: 1;
    .product-item &,
    .modal & {
      width: 1.5rem;
      height: 1.5rem;
      right: 1rem;

      &:before {
        font-size: 1.8rem;
        color: $white;
      }
    }
  }
}
