$white: #ffffff;
$black: #000000;
$red: #f04438;
$primary-color: #004519;
$green-light: #6a9177;
$green-lighter: #c3d0c7;
$green-100: #004519;
$green-80: #316845;
$green-60: #628b70;
$linen: #fdf7f2;
$gray: #1d1d1b;
$gray-1: #0f172a;
$gray-40: #9e9d9d;
$gray-50: #f9fafb;
$gray-60: #737371;
$gray-80: #484846;
$gray-100: #1d1d1b;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-500: #667085;
$yellow: #ffc33c;
$grey: #f7f7fc;
$slate-200: #e2e8f0;
$alto: #d7d7d7;
$error: #b42318;
$conch: #d6e1da;

$font-weight: (
  thin: 100,
  extra-light: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  extra-bold: 800,
  black: 900
);

$regular: map-get($font-weight, regular);
$medium: map-get($font-weight, medium);
$semibold: map-get($font-weight, semibold);
$bold: map-get($font-weight, bold);

$barlow: "Barlow";
$kanit: "Kanit";

$product-border-radius: 10px;
