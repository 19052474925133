h1 {
  margin: 0;
  font-family: $kanit;
  @include font-size-custom(20px);
  $font-weight: $semibold;
  line-height: 3rem;
  color: $primary-color;

  @include media-breakpoint-up(lg) {
    @include font-size-custom(40px);
    line-height: 6rem;
  }
}

h2,
.heading-2 {
  margin: 0 0 3.2rem;
  font-family: $kanit;
  @include font-size-custom(20px);
  $font-weight: $semibold;
  line-height: 3rem;

  @include media-breakpoint-up(lg) {
    @include font-size-custom(30px);
    line-height: 3.2rem;
  }
}

h3,
.heading-3 {
  font-family: $kanit;
  @include font-size-custom(16px);
  $font-weight: $semibold;
  line-height: 1;
  color: $gray-100;
  margin: 0;

  @include media-breakpoint-up(lg) {
    @include font-size-custom(22px);
  }
}

h4,
.heading-4 {
  font-family: $kanit;
  @include font-size-custom(25px);
  $font-weight: $semibold;
  line-height: 1;
  color: $white;
  margin: 0;

  @include media-breakpoint-up(lg) {
    @include font-size-custom(30px);
  }

  &--normal {
    font-weight: $regular;
  }
}

.form-title {
  margin-bottom: 1.6rem;
  text-align: center;

  @include media-breakpoint-up(lg) {
    margin-bottom: 3.2rem;

    .profil-content & {
      margin-bottom: 0.5rem;
    }
  }

  .view-mode & {
    margin: 0;
  }
}
