@import '../../styles/helpers/mixins';
@import '../../styles/helpers/variables';
.loader-custom {
  background: rgba($grey, 0.5);

  &--transparent {
    background: transparent;
  }

  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 6rem;
    height: 6rem;
  }

  &--relative {
    position: relative;
    padding: 2rem;

    .header-right__btn &, .modal-footer & {
      position: absolute;
      top: -1.8rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 0;
    }

    .modal-footer & {
      top: -4.5rem;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.blink {
  width: 20rem;
  height: 5rem;
  animation: fade 1000ms infinite;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid $green-light;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid $primary-color;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
